import {Controller} from '@hotwired/stimulus';
import {debounce} from 'lodash';
import {getUploadManager} from '../helpers/upload_manager';
import {subscribeAll} from '../helpers/eventbus';
import {getAcceptedFilesFromFeature} from '../helpers/accepted_file_types';
import {showToast} from '../helpers/toast_functions';

// function readAsDataURL(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = () => reject(reader.error);
//     reader.readAsDataURL(file);
//   });
// }

// Connects to data-controller="turbo-uploader"
export default class extends Controller {
  static targets = ['dropAreaVisual', 'filesHolder', 'file','inputUpload','uploadButton','showWhileUploading', 'hideWhileUploading','totalPercentage'];
  fileElementByKey = new Map();

  static values = {
    config: Object // Enables the uploader / turbo stream websocket channel when set, allows template to be different
  };

  getContext(){
    return this.configValue?.context;
  }

  getContextType(){
    return this.getContext()?.type;
  }

  get acceptedFilesFeature(){
    return this.configValue?.accepted_files_feature || this.getContextType();
  }

  get maxFiles(){
    return this.configValue?.max_files;
  }

  get uploadsCompleteCallback(){
    return this.configValue?.uploads_complete_callback;
  }

  get currentFilesCount(){
    // FileTargets are everything with a Blob key; PreBlobUploads are the ones which aren't in the template yet / don't have a blob key yet
    return this.fileTargets.length + this.uploadManager.countPreBlobUploads;
  }

  refreshUploadButtonsEnabled(){
    if(this.maxFiles > 0){
      for(let uploadButton of this.uploadButtonTargets){
        uploadButton.disabled = this.currentFilesCount >= this.maxFiles;
      }
    }
  }

  refreshIsUploadingUI(){
    let anyUploading = this.uploadManager.countUploading > 0;

    for(let el of this.showWhileUploadingTargets){
      el.classList[anyUploading ? 'remove' : 'add']('d-none');
    }
    for(let el of this.hideWhileUploadingTargets){
      el.classList[anyUploading ? 'add' : 'remove']('d-none');
    }
  }

  connect() {
    this.unsubscribeAll = subscribeAll(this, {
      'turboUpload:uploadProgress': this.onUploadProgress,
      // 'turboUpload:uploadComplete': this.onUploadComplete,
    });

    this.hideDropAreaVisualDebounced = debounce(this.hideDropAreaVisual.bind(this), 100);

    // Set the accepted files for the input element / file picker
    this.inputUploadTarget.accept = this.acceptedFiles;

    this.refreshUploadButtonsEnabled();
  }
  
  disconnect() {
    this.unsubscribeAll();
  }

  get acceptedFiles() {
    return getAcceptedFilesFromFeature(this.acceptedFilesFeature);
  }

  fileTargetConnected(fileEl){
    let { key, temporaryId } = fileEl.dataset;
    this.fileElementByKey.set(key, fileEl);
    // DirectUpload doesn't tell us the blob key until after the upload is complete, but the TurboStream can tell us as the upload is beginning, so we can then react to upload progress events
    // Without this, the progress events would not include the key, as the Upload would not know it yet
    if(temporaryId){
      this.uploadManager.onLearnedKeyForTemporaryId(temporaryId, key);

      // Could use this to already show the image as it's uploading, but it causes the flicker as the image has to be reloading after upload complete, so maybe nicer to just show filetype placeholder during upload, and get it nicely formated with ImgIx after
      // let upload = this.uploadManager.onLearnedKeyForTemporaryId(temporaryId, key);
      // let imgEl = fileEl.querySelector('img[data-is-image-upload]');
      // if(imgEl){
      //   imgEl.src = await readAsDataURL(upload.file);
      // }
    }

    this.refreshUploadButtonsEnabled();
  }

  fileTargetDisconnected(){
    this.refreshUploadButtonsEnabled();
  }

  onUploadProgress(event){
    let { key, progress } = event;
    if(!key) return;
    let fileEl = this.fileElementByKey.get(key);
    fileEl.querySelector('[data-progress]').innerText = `${progress}%`;

    let {totalPercentageUploaded} = this.uploadManager;
    for(let el of this.totalPercentageTargets){
      el.innerText = `${totalPercentageUploaded}%`;
    }
  }

  showDropAreaVisual() {
    this.hideDropAreaVisualDebounced.cancel();
    this.dropAreaVisualTarget.classList.remove('invisible');
  }

  hideDropAreaVisual() {
    this.dropAreaVisualTarget.classList.add('invisible');
  }

  pickFiles(){
    // pop up file picker
    this.inputUploadTarget.click();
  }

  onFilesPicked() {
    [...this.inputUploadTarget.files].forEach((file) => {
      this.startUpload(file);
    });
  }

  onFilesDropped(event) {
    event.preventDefault();
    this.hideDropAreaVisual();

    // Use DataTransfer interface to access the file(s)
    [...event.dataTransfer.files].forEach((file) => {
      this.startUpload(file);
    });
  }

  onFilesDragEnter() {
    this.showDropAreaVisual();
  }
  onFilesDragOver(event) {
    event.preventDefault();
    this.showDropAreaVisual();
  }
  onFilesDragLeave() {
    this.hideDropAreaVisualDebounced();
  }

  get directUploadUrl(){
    return this.element.getAttribute('data-direct-upload-url');
  }

  get uploadManager(){
    return getUploadManager();
  }

  startUpload(file){
    // Enforce accepted files for drag and drop
    if (this.acceptedFiles && !this.acceptedFiles.includes(file.type)) {
      showToast('danger', `You can't upload files of this type: ${file.name}`);
      return;
    }

    if(this.currentFilesCount >= this.maxFiles){
      showToast('danger', `You have reached the max amount of attachable files: ${this.maxFiles}`);
      return;
    }

    let upload = this.uploadManager.startUpload(file, {
      directUploadUrl: this.directUploadUrl,
      uploadsCompleteCallback: this.uploadsCompleteCallback,
      context: this.getContext(),
    });

    this.refreshIsUploadingUI();

    let div = document.createElement('div');
    // div.className = 'd-none';
    div.id = upload.temporaryId;
    this.filesHolderTarget.appendChild(div);
  }
}

